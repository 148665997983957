import * as React from "react";

import ItemView from "./ItemView";

import logo from "../../Assets/Images/logo.png"; //'/../Assets/Images/project_icon.png';

export default function Home() {
  const handleNotImplemented = () => {
    //toast.success("Not implemented");
  };

  var items = [
    {
      Name: "Portail Admin",
      Description: "Manage Audits, Users and Clients",
      Url: "https://manage.trimaxapp.com/",
    },
    {
      Name: "Portail Auditeur",
      Description: "Submit Audits",
      Url: "https://manage.trimaxapp.com/trimax/main",
    },
    {
      Name: "Portail Client",
      Description: "Review Completed Audits by Location",
      Url: "https://client.trimaxapp.com/",
    },
  ];

  var apps = [
    {
      Name: "Portail Shifts",
      Description: "Shifts, Clients, Users",
      Url: "https://shifts.trimaxapp.com/",
    },
    {
      Name: "Task Dispatcher",
      Description: "Assign Tasks, Find Available Auditors, Reports",
      Url: "https://dispatcher.trimaxapp.com/",
    },
  ];

  var otherItems = [
   
  ];

  return (
    <div class="flex flex-col items-center justify-center  mx-auto ">
      <br/>
      <a href="/" class="flex items-center text-2xl font-semibold text-gray-900 dark:text-white">
        <img class="h-24 mr-2" src={logo} alt="Trimax Logo" />
        Trimax Security
      </a>

      <div class="flex flex-col container max-w-md mt-10 mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
        <h3>Trimax Portal</h3>
        <ul class="flex flex-col divide-y w-full">
          {items.map((item) => (
            <ItemView item={item} />
          ))}
        </ul>
      </div>

{/* 
      <div class="flex flex-col container max-w-md mt-10 mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
        <h3>Not Published</h3>
        <ul class="flex flex-col divide-y w-full">
          {otherItems.map((item) => (
            <ItemView item={item} />
          ))}
        </ul>
      </div>
*/}

      <div class="flex flex-col container max-w-md mt-10 mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
        <h3>Trimax Apps</h3>
        <ul class="flex flex-col divide-y w-full">
          {apps.map((item) => (
            <ItemView item={item} />
          ))}
        </ul>
      </div>
    </div>
  );
}
