
import HubspotForm from "react-hubspot-form"
export default function ContactForm() {


    return (
    <section>


    <div  className="p-8 text-center">
    <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Talk to Technical Specialist</span>

    <div className="flex justify-center p-8">
        <div className="w-[32rem]">
        <HubspotForm   portalId='3111142'
                    formId='24a91fb6-bce1-4df9-a8f9-75bfbefd13f5'
                    onSubmit={() => console.log('Submit!')}
                    onReady={(form) => console.log('Form ready!')}
                    loading={<div>Loading...</div>} />
                    </div>
      </div>
    
    </div>
    </section>
    )
  }