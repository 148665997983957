import * as React from "react";



import logo from "../../Assets/Images/logo.png"; //'/../Assets/Images/project_icon.png';
import ContactForm from "../ContactForm/ContactForm";

export default function Support() {


  return (
    <div class="flex flex-col items-center justify-center  mx-auto ">
      <br/>

      <a href="/" class="flex items-center text-2xl font-semibold text-gray-900 dark:text-white">
        <img class="h-24 mr-2" src={logo} alt="Trimax Logo" />
        Trimax Security
      </a>

   
      <div class="flex flex-col w-auto container  mt-10 mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
        <ContactForm />
      </div>


    </div>
  );
}