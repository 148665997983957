import * as React from 'react';
import { Route, Routes, useLocation, useNavigate,  } from "react-router-dom";

import logo from "../../Assets/Images/item-logo.png"; //'/../Assets/Images/project_icon.png';


export default function ItemView({ item }) {

    const handleClick = () => {
        window.location.href = item.Url;
    };




  return (
    <li class="flex flex-row">
      <div onClick={handleClick} class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
        <div class="flex flex-col justify-center items-center mr-4">
          <a href="#" class="block relative">
           {/* <img alt="profil" src={logo} class="mx-auto object-cover  w-24" /> */} 
          </a>
        </div>
        <div class="flex-1 pl-1">
          <div class="font-medium dark:text-white">{item.Name}</div>
          <div class="text-gray-600 dark:text-gray-200 text-sm">{item.Description}</div>
        </div>
        <div class="flex flex-row justify-center">
          <div class="text-gray-600 dark:text-gray-200 text-xs"></div>
          <button class="w-10 text-right flex justify-end">
            <svg width="20" fill="currentColor" height="20" class="hover:text-gray-800 dark:hover:text-white dark:text-gray-200 text-gray-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
            </svg>
          </button>
        </div>
      </div>
    </li>
  );

}