import logo from './logo.svg';

import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import Home from "./Components/Home/Home";
import Footer from './Components/Core/Footer';
import Header from './Components/Core/Header';
import Support from './Components/Support/Support';

function App() {
  return (
    <div>
      
     <Routes>
        <Route path="/support" element={<Support />} />
        <Route path="/" element={<Home />} />
      </Routes>

    </div>
  );
}

export default App;
